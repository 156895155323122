<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('wanjia_wode') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <div>
        <v-text-field
          v-model="searchplayer"
          filled
          dense
          rounded
          clearable
          no-data-text=""
          hide-details="auto"
          append-icon="mdi-magnify"
          :placeholder="$t('sousuo_tip')"
          @click:append="search"
        />
      </div>

      <div>
        <v-card
          flat
          class="rounded-xl mt-3"
        >
          <v-list-item
            to="MyPlayer_Report"
          >
            <v-list-item-avatar>
              <v-icon
                class="green rounded-lg"
                dark
              >
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('wanjia_shuju') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                icon
              >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>

          <v-divider class="opacity-3" />
          <v-card-text class="pt-2">
            <v-chip-group
              v-model="selectday"
              color="orange"
              class="mb-2"
            >
              <v-chip
                value="day30"
                small
                @click="changeDate(29)"
              >
                {{ $t('dates_30') }}
              </v-chip>
              <v-chip
                value="day7"
                small
                @click="changeDate(6)"
              >
                {{ $t('dates_7') }}
              </v-chip>
              <v-chip
                value="today"
                small
                @click="changeDate(0)"
              >
                {{ $t('dates_today') }}
              </v-chip>
            </v-chip-group>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('wanjia_xinzeng') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ total_players }}
                  /{{ $t('ren') }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('zhuanru') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ total_player_cashout }}
                </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('zhuanchu') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ total_player_topup }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card
          flat
          class="rounded-xl mt-3"
        >
          <v-list>
            <v-subheader>
              {{ $t('wanjia_liebiao') }}
            </v-subheader>

            <v-list-item
              v-for="(item, index) in myplayersearch_list"
              :key="'plist'+index"
              @click="showDetail(item)"
            >
              <v-list-item-avatar>
                <v-img :src="require('../assets/img/avatar/'+item.avatar+'.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.username }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.reg_time }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <img
                    width="16"
                    height="16"
                    src="../assets/img/icons/coin.svg"
                    class="mr-1"
                  >
                  {{ item.available }}
                </div>
              </v-list-item-action>
              <v-list-item-icon>
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <div
            v-if="(page-1)<pagecount"
            class="text-center my-3"
            @click="playerlist"
          >
            <small>{{ $t('jzgd') }}</small>
          </div>
          <div
            v-if="myplayersearch_list.length<1"
            class="text-center text-body-2 opacity-3 pa-10"
          >
            <v-icon
              x-large
              class="my-2"
            >
              mdi-package-variant
            </v-icon>
            <div>
              {{ $t('zanwushuju') }}
            </div>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
  export default {
    data: () => ({
      selectday: 'today',
      picker: [],
      myplayersearch_list: [],
      searchplayer: '',
      page: 1,
      pagecount: 1,
      total_player_cashout: 0,
      total_player_topup: 0,
      total_players: 0
    }),

    computed: {
      
    },
    watch: {
      
    },
    created() {
      if(this.selectday == 'day30'){
        this.picker = [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
      if(this.selectday == 'day7'){
        this.picker = [dayjs().subtract(6, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
      if(this.selectday == 'today'){
        this.picker = [dayjs().subtract(0, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      }
    },
    mounted() {
      this.playerlist()
      this.myplayers_dashboard()
    },
    methods: {
      changeDate(d){
        this.picker = [dayjs().subtract(d, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
        this.myplayers_dashboard()
      },
      myplayers_dashboard() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          startdate:this.picker[0],
          enddate:this.picker[1],
        }
        this.$server.myplayers_dashboard(paramObj).then((response) => {
          if(response.code==200){
            this.total_player_cashout = response.data.total_player_cashout
            this.total_player_topup = response.data.total_player_topup
            this.total_players = response.data.total_players
          }
        })
      },
      search() {
        this.myplayersearch_list = []
        this.page = 1
        this.pagecount = 1
        this.playerlist()
      },
      playerlist() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page:this.page,
          keywords: this.searchplayer
        }
        this.$server.playerlist(paramObj).then((response) => {
          if(response.code==200){
            this.pagecount = response.data.last_page
            if(response.data.data.length>0){
              this.myplayersearch_list.push(...response.data.data)
            }
            ++this.page
          }
        })
      },
      showDetail(item) {
        this.$router.push({
          name: 'MyPlayer_Detail',
          query:{
            id: item.id
          }
        })
      }
    },
  }
</script>